import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useRef } from "react";
import QRcode from "react-qr-code";
import exportAsImage from "../../data/htmltoCanvas";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Background from "../../assets/image/background_refleksi.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Logo from "../../assets/image/logoPemkot.png";
import LogoRakorsus from "../../assets/image/logo_refleksi2.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
        Diskominfo Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Daftarsukses = () => {
  const getParam = useLocation();
  const user = getParam.state.user;

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        // background: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              alignContent: "center",
            }}
          >
            <Box
              alignItems={"center"}
              display={"flex"}
              sx={{ marginY: "25px" }}
            >
              <img src={Logo} alt="logo" height={"150px"} />
            </Box>
            <Box>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography color={'#FFFFFF'} variant="h6">
                  {'Sukses'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography color={'#FFFFFF'} variant="h6">
                {'Data telah diterima !'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default Daftarsukses;
