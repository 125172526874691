import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../auth/auth_provider';
import { headerData } from '../data/headerCostum';
import Logo from '../assets/image/logo_refleksi.png';
import { Divider, Grid } from '@mui/material';
import Left_background from "../assets/image/backgroud_login.png"


function Copyright(props) {
    return (
        <Typography variant="body2" color="darkslategray" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Login = () => {
    const { login } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch(process.env.REACT_APP_API_URL + "api/user/login", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                username: data.get('username'),
                password: data.get('password')
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.token) {
                    login(res);
                }
            })
            .catch(err => {
                console.log(err)
            })
    };
    return (
        <Grid container sx={{
            width: "100%",
            height: "100vh"
        }}>
            <Grid item md={6} sx={{
                background: `url(${Left_background})`,
                backgroundSize: 'cover'
            }}>
            </Grid>
            <Grid item md={6} alignContent={"center"}>
                <Box
                    sx={{
                        // marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        {/* <img src={Logo} alt="karebosi" width={'250px'} /> */}
                    </Box>
                    <Typography textTransform={"uppercase"} variant='h4' fontWeight={700}>
                        {"Performansi Jaringan"}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            height: 2,
                            width: '50%',
                            marginX: 20,
                            marginTop: 1
                        }}
                    />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label=""
                            placeholder='Username'
                            name="username"
                            autoComplete="username"
                            autoFocus
                            size='medium'
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label=""
                            placeholder='Password'
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            size='medium'
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'rgba(130, 12, 15, 1)'
                            }}
                            size='medium'
                        >
                            Sign In
                        </Button>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Box>
            </Grid>
        </Grid>
    );
}

export default Login;