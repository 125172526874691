import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Button, Modal, useTheme, Typography, Grid, Divider, Fade, FormControl, InputLabel, Select, MenuItem, styled, OutlinedInput, ToggleButtonGroup, ToggleButton, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import { headerData, headerDataForm } from "../../../data/headerCostum";
import { Cancel, CheckCircle, EditNoteRounded, ListAltRounded, NoteAltRounded } from "@mui/icons-material";
import Compressor from 'compressorjs';

const bulan = [
    {
        name: "januari",
        value: 1
    },
    {
        name: "februari",
        value: 2
    },
    {
        name: "maret",
        value: 3
    },
    {
        name: "april",
        value: 4
    },
    {
        name: "mei",
        value: 5
    },
    {
        name: "juni",
        value: 6
    },
    {
        name: "juli",
        value: 7
    },
    {
        name: "agustus",
        value: 8
    },
    {
        name: "september",
        value: 9
    },
    {
        name: "oktober",
        value: 10
    },
    {
        name: "november",
        value: 11
    },
    {
        name: "desember",
        value: 12
    },
];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const EditData = ({ execute, id }) => {
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({ id_sid: 'iptransit' })
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [kategori, setKategori] = useState([]);
    const [skpd, setSKPD] = useState(null);
    const [skpdOption, setSKPDOption] = useState(null);
    const [iptransit, setIPTransit] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });
    const [astinet, setAstinet] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });
    const [indibiz, setIndibiz] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });

    const [dataLaporan, setDataLaporan] = useState(null);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    const getDataKategori = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/kategori/", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                setKategori(res)
            })
            .catch(err => {
                console.log(err.message)
            });
    });

    const getDataSKPD = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/skpd", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setSKPD(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [setSKPD])

    const getDataLaporan = () => {
        fetch(process.env.REACT_APP_API_URL + "api/laporan/" + id, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setIPTransit(JSON.parse(data.ip_transit));
                setAstinet(JSON.parse(data.astinet));
                setIndibiz(JSON.parse(data.indibiz));
                setDataLaporan(data);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const sendData = () => {
        let formData = new FormData();
        formData.append('id_kategori', data.id_kategori);
        formData.append('id_skpd', data.id_skpd);
        formData.append('bulan', data.bulan);
        formData.append('tahun', data.tahun);

        formData.append('ip_transit', iptransit.week1);
        formData.append('ip_transit', iptransit.week2);
        formData.append('ip_transit', iptransit.week3);
        formData.append('ip_transit', iptransit.week4);

        formData.append('astinet', astinet.week1);
        formData.append('astinet', astinet.week2);
        formData.append('astinet', astinet.week3);
        formData.append('astinet', astinet.week4);

        formData.append('indibiz', indibiz.week1);
        formData.append('indibiz', indibiz.week2);
        formData.append('indibiz', indibiz.week3);
        formData.append('indibiz', indibiz.week4);

        fetch(process.env.REACT_APP_API_URL + "api/laporan", {
            method: 'post',
            headers: headerDataForm,
            body: formData
        })
            .then(res => {
                execute()
                handleCloseModal()
                return res.json()
            })
            .catch(err => {
                console.log(err)
            });
    };

    const changeKategoriHandle = (id) => {
        setData({ ...data, id_kategori: id })
        const filtered_skpd = skpd.filter(function (items) {
            return items.id_kategori == id;
        })
        setSKPDOption(filtered_skpd);
    }

    useEffect(() => {
        if (!kategori.length) {
            getDataKategori();
        }
        if (!skpd) {
            getDataSKPD();
        }
        if (!dataLaporan) {
            getDataLaporan();
        }
    }, [kategori, skpd, dataLaporan, getDataKategori, getDataSKPD, getDataLaporan])

    const FormFile = ({type}) => {
        return (
            <Grid container item spacing={1} >
                {type[0]
                    ? <Grid container item spacing={1} direction={"row"}>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel disabled><Typography>Week 1: {type[0] ? type[0] : ''}</Typography></InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    endAdornment={
                                        <Button
                                            onClick={() => window.open(process.env.REACT_APP_API_URL + "uploads/laporan/" + type[0], "_blank")}
                                            sx={{
                                                marginLeft: 1,
                                                backgroundColor: '#A6A6A6',
                                                ':hover': {
                                                    backgroundColor: '#6B6B6B',
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: 1,
                                                width: "200px"
                                            }}
                                            size="medium" component="label" variant="contained">
                                            {'Open File'}
                                        </Button>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <></>
                }
                {type[1]
                    ? <Grid container item spacing={1} direction={"row"}>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel disabled><Typography>Week 2:  {type[1] ? type[1] : ''}</Typography></InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    endAdornment={
                                        <Button
                                            onClick={() => window.open(process.env.REACT_APP_API_URL + "uploads/laporan/" + type[1], "_blank")}
                                            sx={{
                                                marginLeft: 1,
                                                backgroundColor: '#A6A6A6',
                                                ':hover': {
                                                    backgroundColor: '#6B6B6B',
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: 1,
                                                width: "200px"
                                            }}
                                            size="medium" component="label" variant="contained">
                                            {'Open File'}
                                        </Button>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <></>
                }
                {type[2]
                    ? <Grid container item spacing={1} direction={"row"}>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel disabled><Typography>Week 3: {type[2] ? type[2] : ''}</Typography></InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    endAdornment={
                                        <Button
                                            onClick={() => window.open(process.env.REACT_APP_API_URL + "uploads/laporan/" + type[2], "_blank")}
                                            sx={{
                                                marginLeft: 1,
                                                backgroundColor: '#A6A6A6',
                                                ':hover': {
                                                    backgroundColor: '#6B6B6B',
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: 1,
                                                width: "200px"
                                            }}
                                            size="medium" component="label" variant="contained">
                                            {'Open File'}
                                        </Button>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <></>
                }
                {type[3]
                    ? <Grid container item spacing={1} direction={"row"}>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel disabled><Typography>Week 4: {type[3] ? type[3] : ''}</Typography></InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    endAdornment={
                                        <Button
                                            onClick={() => window.open(process.env.REACT_APP_API_URL + "uploads/laporan/" + iptransit[3], "_blank")}
                                            sx={{
                                                marginLeft: 1,
                                                backgroundColor: '#A6A6A6',
                                                ':hover': {
                                                    backgroundColor: '#6B6B6B',
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: 1,
                                                width: "200px"
                                            }}
                                            size="medium" component="label" variant="contained">
                                            {'Open File'}
                                        </Button>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <></>
                }
            </Grid>
        );
    }

    const FormWeekly = ({ type }) => {
        switch (type) {
            case "iptransit":
                return (
                    <FormFile type={iptransit} />
                );
            case "astinet":
                return (
                    <FormFile type={astinet} />
                );
            case "indibiz":
                return (
                    <FormFile type={indibiz} />
                );
            default:
                break;
        }
    }

    return (
        <div>
            <Button variant='contained' sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.greenAccent[800]
                },
                marginX: 0.5
            }}
                onClick={handleOpenModal}
                size="small"
            >
                <ListAltRounded />
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container xs={11} md={7} lg={5} sx={style}>
                        <Grid item container justifyContent={"space-between"} alignItems={"end"}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                textTransform={"uppercase"}
                            >
                                {"Data Performansi"}
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container marginTop={1} item spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={dataLaporan ? dataLaporan.Kategori.nama_kategori : ''}
                                    // disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={dataLaporan ? dataLaporan.Skpd.nama_skpd : ''}
                                    // disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item width={"150px"}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="opd-select-label">Bulan</InputLabel>
                                        <Select
                                            labelId="opd-select-label"
                                            id="opd-select"
                                            value={dataLaporan ? dataLaporan.bulan : ''}
                                            label="Instansi"
                                            // onChange={(e) => setData({ ...data, bulan: e.target.value })}
                                            size='small'
                                        >
                                            {
                                                bulan.map((item) => {
                                                    return (
                                                        <MenuItem disabled value={item.value}>
                                                            <Typography textTransform={"capitalize"}>
                                                                {item.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item width={"150px"}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="opd-select-label">Tahun</InputLabel>
                                        <Select
                                            labelId="opd-select-label"
                                            id="opd-select"
                                            value={dataLaporan ? dataLaporan.tahun : ''}
                                            label="Instansi"
                                            onChange={(e) => setData({ ...data, tahun: e.target.value })}
                                            size='small'
                                        >
                                            <MenuItem disabled value={2023}>{"2023"}</MenuItem>
                                            <MenuItem disabled value={2024}>{"2024"}</MenuItem>
                                            <MenuItem disabled value={2025}>{"2025"}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    value={data.id_sid ? data.id_sid : null}
                                    exclusive
                                    onChange={(e) => setData({ ...data, id_sid: e.target.value })}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value={"iptransit"} aria-label="IP Tansit" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"IP Transit"}
                                    </ToggleButton>
                                    <ToggleButton value={"astinet"} aria-label="Astinet" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"Astinet"}
                                    </ToggleButton>
                                    <ToggleButton value={"indibiz"} aria-label="Indibiz" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"Indibiz"}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {
                                data.id_sid
                                    ? <FormWeekly type={data.id_sid} />
                                    : <></>
                            }
                        </Grid>
                        <Grid container item xs={12} marginTop={2} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    backgroundColor: "rgba(154, 60, 62, 1)"
                                }}
                                onClick={() => setOpenModal(false)}
                            >
                                Tutup
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>

        </div>
    );
}

export default EditData;