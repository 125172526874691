import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Button, Modal, useTheme, Typography, Grid, Divider, Fade, FormControl, InputLabel, Select, MenuItem, styled, OutlinedInput, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { tokens } from "../../../theme";
import { headerData, headerDataForm } from "../../../data/headerCostum";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Cancel, CheckCircle } from "@mui/icons-material";
import Compressor from 'compressorjs';

const bulan = [
    {
        name: "januari",
        value: 1
    },
    {
        name: "februari",
        value: 2
    },
    {
        name: "maret",
        value: 3
    },
    {
        name: "april",
        value: 4
    },
    {
        name: "mei",
        value: 5
    },
    {
        name: "juni",
        value: 6
    },
    {
        name: "juli",
        value: 7
    },
    {
        name: "agustus",
        value: 8
    },
    {
        name: "september",
        value: 9
    },
    {
        name: "oktober",
        value: 10
    },
    {
        name: "november",
        value: 11
    },
    {
        name: "desember",
        value: 12
    },
];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const TambahData = ({ execute }) => {
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({ id_sid: 'iptransit' })
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [kategori, setKategori] = useState([]);
    const [skpd, setSKPD] = useState(null);
    const [skpdOption, setSKPDOption] = useState(null);
    const [iptransit, setIPTransit] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });
    const [astinet, setAstinet] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });
    const [indibiz, setIndibiz] = useState({
        week1: null,
        week2: null,
        week3: null,
        week4: null
    });


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    const field = [
        {
            id: "nama",
            label: "Nama",
            placeholder: "Silahkan Masukkan Nama Anda",
            type: "text"
        },
        {
            id: "opd",
            label: "Instansi",
            placeholder: "Silahkan Masukkan Instansi Anda",
            type: "text"
        },
        {
            id: "jabatan",
            label: "Jabatan",
            placeholder: "Silahkan Masukkan Jabatan",
            type: "text"
        },
        {
            id: "no_hp",
            label: "Nomor Handphone",
            placeholder: "Silahkan Masukkan No HP",
            type: "text"
        },
        {
            id: "qr",
            label: "QR Code",
            placeholder: "Silahkan Masukkan QR Code",
            type: "text"
        },
    ];

    const getDataKategori = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/kategori/", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                setKategori(res)
            })
            .catch(err => {
                console.log(err.message)
            });
    });

    const getDataSKPD = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/skpd", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setSKPD(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [setSKPD])

    const sendData = () => {
        let formData = new FormData();
        formData.append('id_kategori', data.id_kategori);
        formData.append('id_skpd', data.id_skpd);
        formData.append('bulan', data.bulan);
        formData.append('tahun', data.tahun);

        formData.append('ip_transit', iptransit.week1);
        formData.append('ip_transit', iptransit.week2);
        formData.append('ip_transit', iptransit.week3);
        formData.append('ip_transit', iptransit.week4);

        formData.append('astinet', astinet.week1);
        formData.append('astinet', astinet.week2);
        formData.append('astinet', astinet.week3);
        formData.append('astinet', astinet.week4);

        formData.append('indibiz', indibiz.week1);
        formData.append('indibiz', indibiz.week2);
        formData.append('indibiz', indibiz.week3);
        formData.append('indibiz', indibiz.week4);

        fetch(process.env.REACT_APP_API_URL + "api/laporan", {
            method: 'post',
            headers: headerDataForm,
            body: formData
        })
            .then(res => {
                execute()
                handleCloseModal()
                return res.json()
            })
            .catch(err => {
                console.log(err)
            });
    };

    const changeKategoriHandle = (id) => {
        setData({ ...data, id_kategori: id })
        const filtered_skpd = skpd.filter(function (items) {
            return items.id_kategori == id;
        })
        setSKPDOption(filtered_skpd);
    }

    useEffect(() => {
        if (!kategori.length) {
            getDataKategori();
        }
        if (!skpd) {
            getDataSKPD();
        }
    }, [kategori, skpd, getDataKategori, getDataSKPD])

    const FormWeekly = ({ type }) => {
        switch (type) {
            case "iptransit":
                return (
                    <Grid container item spacing={1} >
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled><Typography>Week 1: {iptransit.week1 ? iptransit.week1.name : ''}</Typography></InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIPTransit({ ...iptransit, week1: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled><Typography>Week 2: {iptransit.week2 ? iptransit.week2.name : ''}</Typography></InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIPTransit({ ...iptransit, week2: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled><Typography>Week 3: {iptransit.week3 ? iptransit.week3.name : ''}</Typography></InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIPTransit({ ...iptransit, week3: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled><Typography>Week 4: {iptransit.week4 ? iptransit.week4.name : ''}</Typography></InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIPTransit({ ...iptransit, week4: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>


                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case "astinet":
                return (
                    <Grid container item spacing={1}>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 1: {astinet.week1 ? astinet.week1.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setAstinet({ ...astinet, week1: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 2: {astinet.week2 ? astinet.week2.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setAstinet({ ...astinet, week2: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 3: {astinet.week3 ? astinet.week3.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setAstinet({ ...astinet, week3: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 4: {astinet.week4 ? astinet.week4.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setAstinet({ ...astinet, week4: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case "indibiz":
                return (
                    <Grid container item spacing={1}>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 1: {indibiz.week1 ? indibiz.week1.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIndibiz({ ...indibiz, week1: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button onClick={() => console.log(indibiz)} size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 2: {indibiz.week2 ? indibiz.week2.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIndibiz({ ...indibiz, week2: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button onClick={() => console.log(indibiz)} size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 3: {indibiz.week3 ? indibiz.week3.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIndibiz({ ...indibiz, week3: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button onClick={() => console.log(indibiz)} size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} direction={"row"}>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel disabled>Week 4: {indibiz.week4 ? indibiz.week4.name : ''}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <Button
                                                sx={{
                                                    marginLeft: 1,
                                                    backgroundColor: '#A6A6A6',
                                                    ':hover': {
                                                        backgroundColor: '#6B6B6B',
                                                    },
                                                    textTransform: "capitalize",
                                                    borderRadius: 1,
                                                    width: "200px"
                                                }}
                                                size="medium" component="label" variant="contained">
                                                {'Click to Upload'}
                                                <VisuallyHiddenInput type="file" name="file" onChange={
                                                    (ev) => {
                                                        new Compressor(ev.target.files[0], {
                                                            quality: 0.8,
                                                            success: (result) => {
                                                                setIndibiz({ ...indibiz, week4: result });
                                                            },
                                                            error: (error) => {
                                                                console.log(error.message);
                                                            }
                                                        })
                                                    }
                                                } />
                                            </Button>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container xs={4} spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                                direction={"row"}
                            >
                                <Grid item>
                                    <Button onClick={() => console.log(indibiz)} size="medium" color="success">
                                        {/* <CheckCircleOutline  /> */}
                                        <CheckCircle />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size="medium" color="error">
                                        {/* <HighlightOff /> */}
                                        <Cancel />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            default:
                break;
        }
    }

    return (
        <div>
            <Button variant='contained'
                size="large"
                sx={{
                    backgroundColor: "rgba(130, 12, 15, 1)",
                    color: "white",
                    ":hover": {
                        backgroundColor: colors.redAccent[500]
                    },
                    borderRadius: 0,
                }}
                onClick={handleOpenModal}
            >
                <Typography sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
                    textTransform={"capitalize"}
                    fontWeight={600}
                >
                    {"Tambah Data"}
                </Typography>
                <AddRoundedIcon sx={{
                    display: { xs: 'block', sm: 'none' },
                }} />
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container xs={11} md={7} lg={5} sx={style}>
                        <Grid item container justifyContent={"space-between"} alignItems={"end"}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                textTransform={"uppercase"}
                            >
                                {"Form Upload"}
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container marginTop={1} item spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="opd-select-label">Pilih Kategori</InputLabel>
                                    <Select
                                        labelId="opd-select-label"
                                        id="opd-select"
                                        value={data.id_kategori ? data.id_kategori : ''}
                                        label="Instansi"
                                        onChange={(e) => changeKategoriHandle(e.target.value)}
                                        size='small'
                                    >
                                        {
                                            kategori
                                                ? kategori.map((items) => {
                                                    return <MenuItem value={items.id}>{items.nama_kategori}</MenuItem>
                                                })
                                                : <MenuItem value={0}>{"Loading Data . . ."}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="opd-select-label">Pilih SKPD</InputLabel>
                                    <Select
                                        labelId="opd-select-label"
                                        id="opd-select"
                                        value={data.id_skpd ? data.id_skpd : ''}
                                        label="Instansi"
                                        onChange={(e) => setData({ ...data, id_skpd: e.target.value })}
                                        size='small'
                                    >
                                        {
                                            skpdOption
                                                ? skpdOption.map((items) => {
                                                    return <MenuItem value={items.id}>{items.nama_skpd}</MenuItem>
                                                })
                                                : <MenuItem value={0}>{"Loading Data . . ."}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item width={"150px"}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="opd-select-label">Pilih Bulan</InputLabel>
                                        <Select
                                            labelId="opd-select-label"
                                            id="opd-select"
                                            value={data.bulan ? data.bulan : ''}
                                            label="Instansi"
                                            onChange={(e) => setData({ ...data, bulan: e.target.value })}
                                            size='small'
                                        >
                                            {
                                                bulan.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}>
                                                            <Typography textTransform={"capitalize"}>
                                                                {item.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item width={"150px"}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="opd-select-label">Pilih Tahun</InputLabel>
                                        <Select
                                            labelId="opd-select-label"
                                            id="opd-select"
                                            value={data.tahun ? data.tahun : ''}
                                            label="Instansi"
                                            onChange={(e) => setData({ ...data, tahun: e.target.value })}
                                            size='small'
                                        >
                                            <MenuItem value={2023}>{"2023"}</MenuItem>
                                            <MenuItem value={2024}>{"2024"}</MenuItem>
                                            <MenuItem value={2025}>{"2025"}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    value={data.id_sid ? data.id_sid : null}
                                    exclusive
                                    onChange={(e) => setData({ ...data, id_sid: e.target.value })}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value={"iptransit"} aria-label="IP Tansit" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"IP Transit"}
                                    </ToggleButton>
                                    <ToggleButton value={"astinet"} aria-label="Astinet" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"Astinet"}
                                    </ToggleButton>
                                    <ToggleButton value={"indibiz"} aria-label="Indibiz" sx={{
                                        textTransform: 'none'
                                    }}>
                                        {"Indibiz"}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {
                                data.id_sid
                                    ? <FormWeekly type={data.id_sid} />
                                    : <></>
                            }
                        </Grid>
                        <Grid container item xs={12} marginTop={2} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    backgroundColor: "rgba(154, 60, 62, 1)"
                                }}
                                onClick={sendData}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>

        </div>
    );
}

export default TambahData;