import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Typography,
    Button
} from '@mui/material';


import { headerData } from '../../../data/headerCostum';
import TambahData from './tambah';
import EditData from './edit';
import DeleteData from './delete';
import DownloadTable from './download';
import { DownloadDoneOutlined, DownloadOutlined, DownloadRounded, PrintRounded } from '@mui/icons-material';
const downloadColumns = [
    {
        id: 'nama_kategori',
        parent: 'Kategori',
        label: 'Kategori',
    },
    {
        id: 'nama_skpd',
        parent: 'Skpd',
        label: 'SKPD',
    },
    {
        id: 'bulan',
        label: 'Bulan',
        minWidth: 150
    },
    {
        id: 'tahun',
        label: 'Tahun',
        minWidth: 100,
    },
    {
        id: 'ip_transit',
        label: 'IP Transit',
        minWidth: 100,
        format: (value) => {
            return(value)
        }
    },
];

const columns = [
    {
        id: 'Kategori',
        label: 'Kategori',
        minWidth: 150,
        format: (value) => { return (<>{value.nama_kategori}</>) }
    },
    {
        id: 'Skpd',
        label: 'SKPD',
        minWidth: 100,
        format: (value) => { return (<>{value.nama_skpd}</>) }
    },
    {
        id: 'ip_transit',
        label: 'IP Transit',
        minWidth: 100,
        format: (data) => {
            let text_value = ''
            let value = JSON.parse(data);
            let color = 'success';
            if (value.length) {
                text_value = 'Sudah Upload: ' + value.length;
            } else {
                text_value = 'Belum Upload';
                color = 'warning'
            }
            return (
                <Box>
                    <Chip size='small' variant='filled' label={text_value} color={color} />
                </Box>
            )

        }
    },
    {
        id: 'astinet',
        label: 'Astinet',
        minWidth: 100,
        format: (data) => {
            let value = JSON.parse(data);
            let text_value = '';
            let color = 'success';
            if (value.length) {
                text_value = 'Sudah Upload: ' + value.length;
            } else {
                text_value = 'Belum Upload';
                color = 'warning'
            }
            return (
                <Box>
                    <Chip size='small' variant='filled' label={text_value} color={color} />
                </Box>
            )

        }
    },
    {
        id: 'indibiz',
        label: 'Indibiz',
        minWidth: 100,
        format: (data) => {
            let value = JSON.parse(data);
            let text_value = '';
            let color = 'success';
            if (value.length) {
                text_value = 'Sudah Upload: ' + value.length;
            } else {
                text_value = 'Belum Upload';
                color = 'warning'
            }
            return (
                <Box>
                    <Chip size='small' variant='filled' label={text_value} color={color} />
                </Box>
            )

        }
    },
];



export default function Laporan() {
    const [rows, setRows] = useState([])

    //Page Number
    let rowNumber = 0;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //End of Page Number

    const [copyList, setCopyList] = useState(rows);

    //Filter
    const [kategori, setKategori] = useState(null);
    const [id_kategori, setIdKategori] = useState(null);
    //End of Filter

    const getDataLaporan = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/laporan", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setRows(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    const getDataKategori = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/kategori", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setKategori(data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    //Page handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //End of page Handle

    //Filter Hanlde
    const handleKategoriData = useCallback((e) => {
        let data_list = [];
        setIdKategori(e);
        if (parseInt(e) === 0) {
            setCopyList(rows);
        } else {
            rows.forEach((items) => {
                if (parseInt(items.Kategori.id) !== parseInt(e)) {
                    return
                } else {
                    data_list.push(items);
                }
            })
            setCopyList(data_list)
        }
    }, [rows, setCopyList])


    // const opdHandle = useCallback((e) => {
    //     const data = rows.filter((item) =>
    //         item.Laporan.opd.toUpperCase().trim().includes(e.toUpperCase().trim())
    //     )
    //     // setCopyList(data);
    //     setopdValue(e);
    // }, [setopdValue, rows]);

    //End Of Filter handle

    useEffect(() => {
        if (rows.length === 0) {
            getDataLaporan()
        } else {
            setCopyList(rows)
            // getopdamatanData()
        }

        if (!kategori) {
            getDataKategori();
        }
    },
        [getDataLaporan, rows]
    )

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Grid container spacing={1} xs={12} m={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <Box sx={{ marginTop: 0.5, marginBottom: 1 }} >
                        <TambahData execute={getDataLaporan} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ marginTop: 0.5, marginBottom: 1, marginRight:3 }} >
                        <Button
                        variant='contained'
                        color='success'
                        sx={{
                            borderRadius: 0
                        }}
                        onClick={() => window.open('#/admin/print')}
                        >
                            <Typography marginRight={2} textTransform={"none"} fontWeight={700}>
                                {"Cetak Data"}
                            </Typography>
                            <PrintRounded />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1} xs={12} m={1} alignItems={'center'}>
                <Grid item xs={6} md={4} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small' id="opd-select-label">Filter Kategori</InputLabel>
                        <Select
                            labelId="opd-select-label"
                            id="opd-select"
                            value={id_kategori}
                            label="Instansi"
                            onChange={(e) => handleKategoriData(e.target.value)}
                            size='small'
                        >
                            <MenuItem value={0}>{"Semua"}</MenuItem>
                            {
                                kategori
                                    ? kategori.map((items) => {
                                        return <MenuItem value={items.id}>{items.nama_kategori}</MenuItem>
                                    })
                                    : <></>
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={6} md={4} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small' id="opd-select-label">Filter Status</InputLabel>
                        <Select
                            labelId="opd-select-label"
                            id="opd-select"
                            value={opdValue}
                            label="Instansi"
                            // onChange={(e) => opdHandle(e.target.value)}
                            size='small'
                        >
                            {
                                opdData.map((items) => {
                                    return <MenuItem value={items}>{items}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid> */}
            </Grid>
            <TableContainer sx={{ maxHeight: '90vh', marginTop: 4 }}>
                <Table size='small' stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'no'}
                                align={'center'}
                                sx={{ minWidth: 5 }}
                            >
                                {'No'}
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell
                                key={'aksi'}
                                align={'center'}
                                style={{ minWidth: 180 }}
                            >
                                {'Aksi'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {copyList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                rowNumber += 1;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell
                                            align='center'
                                            key={'no'}>
                                            {rowNumber + page * rowsPerPage}
                                        </TableCell>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell
                                            align='center'
                                            key={'aksi'}
                                        >
                                            <Grid container item>
                                                <EditData id={row.id} execute={getDataLaporan} />
                                                <DeleteData id={row.id} execute={getDataLaporan} />
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={copyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}