import { Box, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { headerData } from "../../data/headerCostum";

const bulan = [
  {
    name: "januari",
    value: 1
  },
  {
    name: "februari",
    value: 2
  },
  {
    name: "maret",
    value: 3
  },
  {
    name: "april",
    value: 4
  },
  {
    name: "mei",
    value: 5
  },
  {
    name: "juni",
    value: 6
  },
  {
    name: "juli",
    value: 7
  },
  {
    name: "agustus",
    value: 8
  },
  {
    name: "september",
    value: 9
  },
  {
    name: "oktober",
    value: 10
  },
  {
    name: "november",
    value: 11
  },
  {
    name: "desember",
    value: 12
  },
];

const laporan = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const Dashboard = () => {
  const [kategori, setkategori] = useState(null);
  const [counKategori, setCountKategori] = useState(null);
  const [counSkpd, setCountSkpd] = useState(null);

  const getDataKategori = () => {
    fetch(process.env.REACT_APP_API_URL + "api/kategori", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setkategori(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getCountKategori = () => {
    fetch(process.env.REACT_APP_API_URL + "api/laporan/count", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setCountKategori(data);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getCountSKPD = () => {
    fetch(process.env.REACT_APP_API_URL + "api/skpd/count", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setCountSkpd(data);
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!kategori) {
      getDataKategori();
    }
    if (!counKategori) {
      getCountKategori();
    }
    if (!counSkpd) {
      getCountSKPD();
    }
  }, []);

  return (
    <Box>
      <Grid container direction={"column"} mt={2}>
        <Grid item container>
          <Typography variant="h3" textTransform={"uppercase"} fontWeight={700}>
            {"Dashboard Statistik Jaringan"}
          </Typography>
        </Grid>
        <Grid item container spacing={2} marginTop={2}>
          {/* <Grid item width={"150px"} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Pilih Bulan</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Bulan"
              // onChange={handleChange}
              >
                {
                  bulan.map((items) => {
                    return (
                      <MenuItem value={items.value}>
                        <Typography textTransform={"capitalize"}>{items.name}</Typography>
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item width={"150px"}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Pilih Tahun</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Age"
              // onChange={handleChange}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Grid item container marginTop={2} paddingRight={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={50}>
                    <Typography fontWeight={700}>{"No"}</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 150 }}>
                    <Typography fontWeight={700}>{"Organisasi Perangkat Daerah"}</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 150 }}>
                    <Typography fontWeight={700}>{"Selesai"}</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 150 }}>
                    <Typography fontWeight={700}>{"Belum Selesai"}</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ width: 150 }}>
                    <Typography fontWeight={700}>{"Progress"}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  counSkpd
                    ? counSkpd.map((row, index) => {
                      let jumlah_belum = parseInt(row.jumlah)
                      let jumlah_sudah = 0
                      if (counKategori) {
                        counKategori.forEach(element => {
                          if (element.id_kategori === row.id_kategori) {
                            jumlah_belum -= parseInt(element.jumlah)
                            jumlah_sudah = parseInt(element.jumlah)
                          }
                        });
                      }
                      return (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">{row.Kategori.nama_kategori}</TableCell>
                          <TableCell align="center">{jumlah_sudah}</TableCell>
                          <TableCell align="center">{jumlah_belum}</TableCell>
                          <TableCell align="center">
                            <LinearProgress
                              value={(jumlah_sudah / jumlah_belum) * 100}
                              color={(jumlah_sudah / jumlah_belum) > 0.5 ? "success" : "warning"}
                              variant="determinate"
                              sx={{
                                height: 10
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : <></>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
