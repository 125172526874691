import React, { Suspense } from "react";
import { Alert, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
  defer,
  Route,
  useOutlet,
  useLoaderData,
  Await,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./auth/auth_provider";
// import { AuthProvider, useAuth } from './auth/auth_provider';
import Daftarsukses from "./scenes/pendaftaran/sukses";
import Login from "./scenes/login";
import SplashScreen from "./scenes/global/spashscreen";
import DaftarUser from "./scenes/admin/akun";
import Dashboard from "./scenes/admin/dashboard";
import SideMenu from "./scenes/global/Menu";

import Laporan from "./scenes/admin/laporan";
import DaftarKategori from "./scenes/admin/kategori";
import DaftarSKPD from "./scenes/admin/skpd";
import PrintPDF from "./scenes/admin/laporan/download_laporan";
import ViewData from "./scenes/admin/laporan/download";

const App = () => {
  const [theme, colorMode] = useMode();
  // const { user } = useAuth();
  const router = createHashRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route index element={<Login />} />
        {/* <Route path="konten" element={<Konten />} /> */}
        {/* <Route path="pdashboard" element={<PublicDashboard />} /> */}
        <Route path="sukses" element={<Daftarsukses />} />
        <Route path="login" element={<Login />} />
        <Route path="admin" element={<ProtectedLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="laporan" element={<Laporan />} />
          <Route path="daftaruser" element={<DaftarUser />} />
          <Route path="daftarkategori" element={<DaftarKategori />} />
          <Route path="daftarskpd" element={<DaftarSKPD />} />
          <Route path="print" element={<ViewData />} />
        </Route>
      </Route>
    )
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
      reject("Error");
    }, 3000)
  );

const ProtectedLayout = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <SideMenu />
    </div>
  );
};

const AuthLayout = () => {
  const oulet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={<SplashScreen />}>
      <Await
        resolve={userPromise}
        errorElement={
          <Alert severity="error">
            404: Halaman yang anda Cari tidak tersedia
          </Alert>
        }
        children={(user) => (
          <AuthProvider userData={user}>{oulet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};

export default App;
