import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
    Button, Modal,
    useTheme, Typography,
    Grid, TextField,
    Divider, Fade,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import { tokens } from "../../../theme";
import { headerData } from "../../../data/headerCostum";
import ShowAlert from "../../global/alert";

const TambahData = ({ execute }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [alertOption, setAlertOption] = useState({
        title: '',
        desc: '',
        type: 'info'
    });
    const [openAlert, setOpenAlert] = useState(false);
    const hadleAlert = () => {
        setOpenAlert(false);
    };

    const [dataskpd, setdataskpd] = useState({
        id_kategori: null,
        nama_skpd: null
    });

    const [dataKategori, setDataKategori] = useState(null)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        bgcolor: colors.primary[400],
        p: 3,
        borderRadius: 2
    };

    const getDataKategori = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/kategori/", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                setDataKategori(res)
            })
            .catch(err => {
                console.log(err.message)
            });
    })

    const sendData = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/skpd/", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify(dataskpd)
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                execute()
                if (data.code === 500) {
                    setAlertOption(
                        {
                            title: 'Gagal',
                            desc: data.message,
                            type: 'error'
                        }
                    );
                } else if (data.code === 201) {
                    setAlertOption(
                        {
                            title: 'Sukses',
                            desc: data.message,
                            type: 'success'
                        }
                    );
                    handleCloseModal()
                }
                setOpenAlert(true);
                setTimeout(hadleAlert, 4000);
            })
            .catch(err => {
                console.log(err)
            })
    }, [dataskpd, execute]);

    useEffect(() => {
        if (!dataKategori) {
            getDataKategori();
        }
    })


    return (
        <div>
            <ShowAlert
                title={alertOption.title}
                desc={alertOption.desc}
                type={alertOption.type}
                openAlert={openAlert}
                onAlertClose={hadleAlert}
            />
            <Button variant='contained' sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ":hover": {
                    backgroundColor: colors.greenAccent[800]
                }
            }}
                onClick={handleOpenModal}
            >
                <Typography>
                    Tambah
                </Typography>
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <Grid container xs={11} md={7} lg={5} sx={style}>
                        <Grid item container justifyContent={"space-between"} alignItems={"end"}>
                            <Typography variant="h4">
                                {"Tambah skpd"}
                            </Typography>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minWidth: 25
                                }}
                                onClick={handleCloseModal}
                            >
                                <Typography variant="button" color={colors.redAccent[400]}>
                                    x
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Divider />
                        </Grid>
                        <Grid container item m={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="opd-select-label">Pilih Kategori</InputLabel>
                                    <Select
                                        labelId="opd-select-label"
                                        id="opd-select"
                                        value={dataskpd.id_kategori}
                                        label="Instansi"
                                        onChange={(e) => setdataskpd({...dataskpd,  id_kategori: e.target.value })}
                                        size='small'
                                    >
                                        {
                                            dataKategori
                                                ? dataKategori.map((items) => {
                                                    return <MenuItem value={items.id}>{items.nama_kategori}</MenuItem>
                                                })
                                                : <MenuItem value={0}>{"Loading data . . ."}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>

                                <TextField
                                    id={'nama_skpd'}
                                    value={dataskpd.nama_skpd}
                                    label={'Nama SKPD'}
                                    placeholder={'Silahkan masukkan nama skpd'}
                                    variant="outlined"
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    sx={{
                                        marginTop: "10px",
                                        ":target-text": {
                                            borderColor: colors.greenAccent[400]
                                        }
                                    }}
                                    onInput={(e) => setdataskpd({ ...dataskpd, nama_skpd: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} m={1} justifyContent={"end"}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colors.greenAccent[500]
                                }}
                                onClick={() => sendData()}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>

        </div>
    );
}

export default TambahData;